<template>
  <section>
    <slot />
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss"></style>
