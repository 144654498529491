<template>
  <img src="@/assets/images/scritta-bianca-cane-blu-copia.png" alt="Ercole" />
</template>

<script>
export default {
  name: 'commom-ercolelogo-component',
}
</script>

<style lang="scss" scoped></style>
