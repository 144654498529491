var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.notShowing)?_c('section',{staticClass:"filters"},[_c('b-button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
      _vm.options(
        _vm.globalFilters.hasActiveFilters
          ? _vm.$t('common.globalFilters.active')
          : _vm.$t('common.globalFilters.inactive')
      )
    ),expression:"\n      options(\n        globalFilters.hasActiveFilters\n          ? $t('common.globalFilters.active')\n          : $t('common.globalFilters.inactive')\n      )\n    ",modifiers:{"bottom":true}}],staticClass:"bt-show-hide-filters",attrs:{"size":"is-small","type":"is-light","pack":"fas","icon-right":_vm.filterIcon,"expanded":""},on:{"click":_vm.expandFilters}},[_c('b-icon',{staticStyle:{"margin":"-2px 0 0 0"},attrs:{"type":{ 'is-primary': _vm.globalFilters.hasActiveFilters },"icon":"filter","size":"is-small","pack":"fas"}}),_vm._v(" "+_vm._s(_vm.isFiltersOpened ? _vm.$t('common.globalFilters.hide') : _vm.$t('common.globalFilters.show'))+" ")],1),(_vm.globalFilters.hasActiveFilters)?_c('div',{staticClass:"activeFilters"},[_c('div',{staticClass:"has-text-weight-medium is-flex"},[_vm._v(" "+_vm._s(_vm.$t('common.globalFilters.activeFilters'))+" "),(_vm.glFilters.location)?_c('div',{staticClass:"field ml-2"},[_c('b-tag',{attrs:{"type":"is-warning is-light"}},[_vm._v(" "+_vm._s(_vm.glFilters.location)+" ")])],1):_vm._e(),(_vm.glFilters.environment)?_c('div',{staticClass:"field ml-2"},[_c('b-tag',{attrs:{"type":"is-warning is-light"}},[_vm._v(" "+_vm._s(_vm.glFilters.environment)+" ")])],1):_vm._e(),(_vm.glFilters.date)?_c('div',{staticClass:"field ml-2"},[_c('b-tag',{attrs:{"type":"is-warning is-light"}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.glFilters.date))+" ")])],1):_vm._e()])]):_vm._e(),_c('b-collapse',{attrs:{"animation":"slide","open":_vm.isFiltersOpened},on:{"update:open":function($event){_vm.isFiltersOpened=$event}}},[_c('div',{staticClass:"filters-form"},[_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.$route.name !== 'hosts-details' && _vm.$route.name !== 'cluster-details'
        ),expression:"\n          $route.name !== 'hosts-details' && $route.name !== 'cluster-details'\n        "}],staticClass:"filters-field",attrs:{"label":_vm.$t('common.globalFilters.location'),"custom-class":"is-size-7","horizontal":""}},[_c('b-select',{attrs:{"size":"is-small","placeholder":`${_vm.$t('common.forms.select')} ${_vm.$t(
            'common.globalFilters.locationPH'
          )}`,"expanded":""},model:{value:(_vm.glFilters.location),callback:function ($$v) {_vm.$set(_vm.glFilters, "location", $$v)},expression:"glFilters.location"}},[(_vm.glFilters.location)?_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.$t('common.forms.reset'))+" "+_vm._s(_vm.$t('common.globalFilters.location'))+" ")]):_vm._e(),_vm._l((_vm.globalFilters.locations),function(loc,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(loc)+" ")])})],2)],1),_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.$route.name !== 'hosts-details' && _vm.$route.name !== 'cluster-details'
        ),expression:"\n          $route.name !== 'hosts-details' && $route.name !== 'cluster-details'\n        "}],staticClass:"filters-field",attrs:{"label":_vm.$t('common.globalFilters.environment'),"custom-class":"is-size-7","horizontal":""}},[_c('b-select',{attrs:{"size":"is-small","placeholder":`${_vm.$t('common.forms.select')} ${_vm.$t(
            'common.globalFilters.environmentPH'
          )}`,"expanded":""},model:{value:(_vm.glFilters.environment),callback:function ($$v) {_vm.$set(_vm.glFilters, "environment", $$v)},expression:"glFilters.environment"}},[(_vm.glFilters.environment)?_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.$t('common.forms.reset'))+" "+_vm._s(_vm.$t('common.globalFilters.environment'))+" ")]):_vm._e(),_vm._l((_vm.globalFilters.environments),function(env,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(env)+" ")])})],2)],1),_c('b-field',{staticClass:"filters-field",attrs:{"label":_vm.$t('common.globalFilters.date'),"custom-class":"is-size-7","horizontal":""}},[_c('b-datepicker',{attrs:{"date-formatter":_vm.formatDate,"size":"is-small","placeholder":`${_vm.$t('common.forms.select')} ${_vm.$t(
            'common.globalFilters.date'
          )}`,"icon":"calendar-today","trap-focus":"","expanded":"","editable":"","max-date":new Date()},model:{value:(_vm.glFilters.date),callback:function ($$v) {_vm.$set(_vm.glFilters, "date", $$v)},expression:"glFilters.date"}})],1),_c('div',{staticClass:"filter-buttons"},[_c('b-button',{staticClass:"filters-button",attrs:{"size":"is-small","type":"is-custom-primary"},on:{"click":_vm.applyFilters}},[_vm._v(" "+_vm._s(_vm.$t('common.forms.apply'))+" ")]),_c('b-button',{staticClass:"filters-button",attrs:{"size":"is-small","type":"is-danger"},on:{"click":_vm.resetFilters}},[_vm._v(" "+_vm._s(_vm.$t('common.forms.reset'))+" ")])],1)],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }